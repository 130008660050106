import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, FacturasService} from "services";
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {MenuItem, TextField, Tooltip, Typography} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

const FILTROS_DESDE = [{
    id: 'dia',
    description: 'Desde hoy'
},{
    id: 'mes',
    description: 'Desde 30 días'
}];

export class FacturasList extends Component {
    state = {
        viewType: this.props.match.params.cliente ? "xcliente" : "xfecha",
        cliente: {},
        desde: localStorage.getItem('ventas.facturas.list.desde') || 'mes',
        soloGranel: localStorage.getItem('ventas.facturas.list.soloGranel') || false,
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'factura', title: 'N° Documento'},
            {name: 'custom1', title: ' '},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'importe', title: 'Importe'},
            {name: 'cliente', title: 'Cliente', style: {whiteSpace: 'break-spaces'}},
        ],
        columnExtensions: [
            {columnName: 'id', compare: sortNumeric, direction: 'desc', width: 100},
            {columnName: 'factura', width: 150},
            {
                columnName: 'custom1',
                width: 100,
                component: props => props.row.isPrepago ? <Tooltip title={"Tool"}><Typography color={"primary"} variant={"body2"}>Prepago</Typography></Tooltip> : '',
                sortingEnabled: false
            },
            {columnName: 'ingreso', compare: sortDate, direction: 'desc', width: 110},
            {columnName: 'importe', type: 'currency', compare: sortNumeric, width: 130},
        ],
        rows: []
    };

    ClientesService = new ClientesService(this.context);
    FacturasService = new FacturasService(this.context);

    componentDidMount() {
        if (this.state.viewType === "xcliente")
            this.loadFacturasXCliente();
        else
            this.loadFacturasXFecha();
    }

    loadFacturasXFecha = () => {
        this.FacturasService.getAll(
            this.state.desde,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    };

    loadFacturasXCliente = () => {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.FacturasService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    };

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    changeDesde = (desde) => {
        this.setState({desde}, () => {
            localStorage.setItem("ventas.facturas.list.desde", desde);
            this.loadFacturasXFecha();
        });
    }

    toggleSoloGranel = () => this.setState({soloGranel: !this.state.soloGranel}, () => {
        localStorage.setItem("ventas.facturas.list.soloGranel", this.state.soloGranel);
        this.loadFacturasXFecha();
    });

    render() {
        const {cliente, columns, columnExtensions, rows, viewType, soloGranel} = this.state;
        return (
            <Box className='niquel-facturas-list'>
                <SubHeader
                    title={'Facturas'}
                    subtitle={viewType === "xcliente" ? "Facturas de Venta por Cliente" : "Facturas de Venta por Fecha de Ingreso"}
                    cliente={viewType === "xcliente" ? cliente : ""}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Facturas del sistema (${rows.length})`}
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                        customBtn2={
                            viewType === "xfecha" &&
                            <Box className={'ml-2'}>
                                <Switch
                                    checked={soloGranel}
                                    onChange={this.toggleSoloGranel}
                                    name="checkedA"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                />
                                <Typography variant={"body2"} component={"span"}>Solo Granel</Typography>
                            </Box>
                        }
                        customBtn1={
                            viewType === "xfecha" &&
                            <TextField
                                select
                                className={'ml-2'}
                                style={{width: '150px'}}
                                value={this.state.desde}
                                onChange={event => this.changeDesde(event.target.value)}
                            >
                                {FILTROS_DESDE.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    />
                </Box>
            </Box>
        )
    }
}

FacturasList.contextType = MainContext;
FacturasList = withSnackbar(FacturasList);
